import React from 'react';
import { Menu } from 'components/Menu';

const LeftBar = () => {
  return (
    <div className="sm:hidden sticky top-[92px] flex-shrink-0 leftside-height w-[280px] flex flex-col justify-between items-center text-white pt-3 px-6 py-10 space-y-9">
      <Menu />
    </div>
  );
};

export default LeftBar;
