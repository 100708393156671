export const SOLANA_MENU_LIST = {
  menu: [
    {
      icon: '/icons/market.svg',
      title: 'Market',
      link: '/market'
    },
    {
      icon: '/icons/menu.svg',
      title: 'My tokens',
      link: '/myTokens'
    }
  ],
  pumpfun: [
    {
      icon: '/icons/bundler.svg',
      title: 'Bundler',
      link: '/bundler'
    }
  ],
  v3: [
    {
      icon: '/icons/circle-plus.svg',
      title: 'Create v3 token',
      link: '/createToken'
    },
    {
      icon: '/icons/list-apply.svg',
      title: 'Create liquidity pool',
      link: '/createLiquidityPool'
    }
  ],
  other: [
    {
      icon: '/icons/referral.svg',
      title: 'Referral system',
      link: '/referral'
    },
    {
      icon: '/icons/support.svg',
      title: 'Support',
      link: '/support'
    },
    {
      icon: '/icons/faq.svg',
      title: 'FAQ',
      link: '/faq'
    },
    {
      icon: '/icons/logout.svg',
      title: 'Log out',
      link: '/logout'
    }
  ]
};

export const TON_MENU_LIST = {
  menu: [
    {
      icon: '/icons/market.svg',
      title: 'Market',
      link: '/market'
    }
  ],
  other: [
    {
      icon: '/icons/support.svg',
      title: 'Support',
      link: '/support'
    },
    {
      icon: '/icons/faq.svg',
      title: 'FAQ',
      link: '/faq'
    },
    {
      icon: '/icons/logout.svg',
      title: 'Log out',
      link: '/logout'
    }
  ]
};

export const DEFAULT_MENU_LIST = {
  menu: [
    {
      icon: '/icons/market.svg',
      title: 'Market',
      link: '/market'
    }
  ],
  other: [
    {
      icon: '/icons/support.svg',
      title: 'Support',
      link: '/support'
    },
    {
      icon: '/icons/faq.svg',
      title: 'FAQ',
      link: '/faq'
    },
    {
      icon: '/icons/logout.svg',
      title: 'Log out',
      link: '/logout'
    }
  ]
};
